<template>
  <div ref="display"></div>
</template>
<script>
import Vue from "vue";
import * as echarts from "echarts";
export default {
  data() {
    return {
      component: null,
      js: null,
      css: null,
      html: null,
      id: this.randomStr(),
      code: `<template>
        <div class="recently-total-trend">
          <div class="chart-container" ref="chartDom" ><\/div>
        <\/div>
      <\/template>
      import {xxx} from '@/api/xxx.js'
      <script>
      export default {
        data(){
          return {
            myChart: null
          }
        },
        methods: {
          initCharts() {
            const chartDom = this.$refs.chartDom
            console.log('xxx', chartDom)
            this.myChart = $echarts.init(chartDom);
            let option = {
              xAxis: {
                  type: 'category',
                  data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
              },
              yAxis: {
                  type: 'value'
              },
              series: [{
                  data: [820, 932, 901, 934, 1290, 1330, 1320],
                  type: 'line'
              }]
            };
            this.myChart.setOption(option, false);
          },
        },
      };
      <\/script>

      <style lang="less" scoped>
        .recently-total-trend {
          width: 300px;
          height: 270px;
          background: #ffffff;
          border-radius: 10px;
          padding: 14px 20px;
          .chart-container {
            width: 100%;
            height: 210px;
            margin-top: 10px;
          }
        }
      <\/style>`,
    };
  },
  methods: {
    randomStr(len = 32) {
      const $chars =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
      const maxPos = $chars.length;
      let str = "";
      for (let i = 0; i < len; i++) {
        str += $chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return str;
    },
    renderCode() {
      this.splitCode();

      if (this.html !== "" && this.js !== "") {
        if (this.css !== "") {
          const style = document.createElement("style");
          style.type = "text/css";
          style.id = this.id;
          style.innerHTML = this.css;
          document.getElementsByTagName("head")[0].appendChild(style);
        }
        const parseStrToFunc = new Function(this.js)();

        parseStrToFunc.template = this.html;
        const Component = Vue.extend(parseStrToFunc);
        this.component = new Component().$mount();
        this.$refs.display.appendChild(this.component.$el);
        this.component.initCharts();
        console.log("xxx-end", new Date().getTime());
      }
    },
    getSource(source, type) {
      const regex = new RegExp(`<${type}[^>]*>`);
      let openingTag = source.match(regex);

      if (!openingTag) return "";
      else openingTag = openingTag[0];

      return source.slice(
        source.indexOf(openingTag) + openingTag.length,
        source.lastIndexOf(`</${type}>`)
      );
    },
    splitCode() {
      const script = this.getSource(this.code, "script").replace(
        /export default/,
        "return "
      );
      const style = this.getSource(this.code, "style");
      const template =
        '<div id="xxx">' + this.getSource(this.code, "template") + "</div>";

      this.js = script;
      this.css = style;
      this.html = template;
    },
  },
  created() {
    window.$echarts = echarts;
  },
  mounted() {
    console.log("xxx-start", new Date().getTime());
    this.renderCode();
  },
};
</script>